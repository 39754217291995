/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@use "bootstrap";

@import '~@ionic/angular/css/core.css';

/* Basic CSS for apps built with Ionic */
@import '~@ionic/angular/css/normalize.css';
@import '~@ionic/angular/css/structure.css';
@import '~@ionic/angular/css/typography.css';
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import '~@ionic/angular/css/padding.css';
@import '~@ionic/angular/css/float-elements.css';
@import '~@ionic/angular/css/text-alignment.css';
@import '~@ionic/angular/css/text-transformation.css';
@import '~@ionic/angular/css/flex-utils.css';

/*
 * Global styles
 * TODO: Move to shared stylesheet between apps and import it
 */

.text-selectable {
  user-select: text;
}

.no-inner-padding{
  --padding-start: 0px !important;
  --inner-padding-start: 0px !important;
  --inner-padding-end:   0px !important;
}

ion-split-pane {
  --side-width: 250px;
}

.cdk-virtual-scroll-content-wrapper {
  width: 100%; // Enable list horizontal scaling on storage
  contain: none !important; // Enable horizontal scrolling on table on parent element
}

// Fix search bar padding on iOS and alignment, css utilities not applying
.md ion-searchbar {
  border: 1px solid var(--ion-border-color);
  padding: 0px 0px 0px 0px !important;
  margin-left: 0px !important;
  margin-right: 20px !important;
  margin-top: 16px;
  margin-bottom: 8px;
}

.ios ion-searchbar {
  width: 99%;
}

// Fix ngx avatar thumbnail vertical stretching when title too long
ngx-avatars div.avatar-content {
  max-height: 56px;
}

// disable card styles on mobile to make less bulky
// FIXME: Causes weird artefacts on dark mode, this is primarily for forms layout, adjust forms to improve this ui
// @media only screen and (max-width: 576px) {
//   ion-card {
//     margin: 0;
//     box-shadow: none;
//     background: transparent;

//     ion-card-header, ion-card-content {
//       padding-left: 0 !important;
//       padding-right: 0 !important;
//     }
//   }
// }

ion-spinner {
  transform: scale(0.6);
}

*{
  box-shadow: none !important;
}

ion-card{
  box-shadow: none !important;
}


//DASHBOARDS
// Makes the iframe responsive
.embedded-container {
  position: relative;
  // padding-bottom: 56.25%;
  padding-top: 25px;
  // height: 0;
}
.embedded-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.safe-padding {
  padding-top: var(--safe-area-inset-top, 0px);
  padding-right: var(--safe-area-inset-right, 0px);
  padding-bottom: var(--safe-area-inset-bottom, 0px);
  padding-left: var(--safe-area-inset-left, 0px);
}

.embedded-container-fullscreen {
  width: 100%;
  height: 100%;
  @extend .safe-padding;
}

.embedded-container-fullscreen iframe {
  width: 100%;
  height: 100%;
  @extend .safe-padding;
}

.fullscreen {
  @extend .safe-padding;
  ion-card {
    height: 100%;
  }
  ion-card-content {
    height: 100%;
  }
  iframe {
    height: 100%;
  }
  agm-map {
    height: 100% !important;
  }
}

.fullscreen-modal {
  .modal-wrapper {
    height: 100%;
    width: 100%;
    @extend .safe-padding;
  }
}

/** Fixed height so error messages does not expand form */
.input-with-error-container {
  height: 90px;
}

.active-item,
.active-item > ion-icon {
  // --background: #f2f2f2;
  --background: rgba(var(--ion-color-secondary-rgb), 0.14);
  --color: var(--ion-color-secondary);
  color: var(--ion-color-secondary);
}

.active-breadcrumb,

.required:after {
  content: ' *';
  // --color: var(--ion-color-danger, red); // When to use this?
  color: var(--ion-color-danger, red);
}

.primaryKey:after {
  content: 'pk';
  // --color: var(--ion-color-danger, red); // When to use this?
  color: var(--ion-color-danger, green);
}

.unique:after {
  content: 'u';
  // --color: var(--ion-color-danger, red); // When to use this?
  color: var(--ion-color-danger, blue);
}

.clickable {
  cursor: pointer;
}

// Avoids blurry downsampled images in chrome
img {
  // Removes blur from downscaled chrome
  image-rendering: -webkit-optimize-contrast;
}

@media (prefers-color-scheme: light) {
  .logo-light {
    display: block;
  }

  .logo-dark {
    display: none;
  }

  .safe-area-inset-top-root {
    border-top: var(--safe-area-inset-top,0px) solid #fff;
  }
}

@media (prefers-color-scheme: dark) {
  .logo-light {
    display: none;
  }

  .logo-dark {
    display: block;
  }

  .markdown-container {
    color: white;
  }

  .safe-area-inset-top-root {
    border-top: var(--safe-area-inset-top,0px) solid var(--ion-item-background);
  }
}

.markdown-container a:hover {
  color: var(--ion-color-secondary) !important;
  text-decoration: underline !important;
}

.markdown-container a {
  text-decoration: underline !important; 
  color: var(--ion-color-secondary) !important; 
}



a:hover {
  color: var(--ion-color-secondary) !important;
}

a {
  text-decoration: none !important;
  color: var(--ion-color-dark) !important;
}

// iOS styling

ion-item-divider .ios {
  padding: 8px 0px 8px 0px;
}

// make dark move forms not look so weird on ios
ion-card.ios {
  background: transparent !important;
}

ion-thumbnail {
  margin-top: 8px;
  margin-bottom: 8px;
}

// This is needed for the file drag and drop
.file-drop-drop-zone {
  width: 100%;
  height: 100%;
  text-align: center;
}

.file-drop-content {
  width: 100%;
  height: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

// Accordion
@media (prefers-color-scheme: light) {
  .accordion-header-outline {
    border: 1px solid #f4f5f8;
    border-radius: 0.2em 0.2em 0em 0em;
  }

  .accordion-content-outline {
    border: 1px solid #f4f5f8;
    border-width: 0px 1px 1px 1px;
    border-radius: 0em 0em 0.2em 0.2em;
  }
}

@media (prefers-color-scheme: dark) {
  .accordion-header-outline {
    border: 1px solid #222428;
    border-radius: 0.2em 0.2em 0em 0em;
  }

  .accordion-content-outline {
    border: 1px solid #222428;
    border-width: 0px 1px 1px 1px;
    border-radius: 0em 0em 0.2em 0.2em;
  }
}

// Popover

ion-popover.search-popover {
  --min-width: 40%;
}

ion-popover.table-search-popover {
  --min-width: 40%;
  & > .popover-content {
    --min-width: 40%;
  }
}

@media only screen and (max-width: 992px) {
  ion-popover.search-popover {
    --min-width: 60%;
    & > .popover-content {
      --min-width: 60%;
    }
  }
}

@media only screen and (max-width: 768px) {
  ion-popover.search-popover {
    --min-width: 95%;
    & > .popover-content {
      --min-width: 90%;
    }
  }
}

@media only screen and (max-width: 992px) {
  ion-popover.table-search-popover {
    --min-width: 60%;
    & > .popover-content {
      --min-width: 60%;
    }
  }
}

@media only screen and (max-width: 768px) {
  ion-popover.table-search-popover {
    --min-width: 95%;
    & > .popover-content {
      --min-width: 90%;
    }
  }
}

// utils
.full-height {
  overflow: auto;
  height: 100%;
}

.grid-fitting {
  overflow: auto;
  width: 100%;
}

.click-cursor:hover {
  cursor: pointer;
}

.avatar-container {
  display: inline-block;
  margin-right: 0.5em;
}

ngx-avatars img {
  object-fit: cover;
}

.grid-no-padding {
  padding: 0;

  ion-grid {
    padding: 0;
  }

  ion-col {
    padding-left: 0;
    padding-right: 0;
  }
}

.ion-page {
  height: 100% !important;
  width: 100% !important;
}

.kanban-container {
  display: flex;
  justify-content: space-between;
  overflow-x: auto;
}

.kanban-column {
  width: 300px;
  margin-right: 16px;
}

.kanban-card {
  margin-bottom: 16px;
}

.clickable-link {
  color: var(--ion-color-secondary);  /* Use the primary color */
  cursor: pointer;                  /* Ensure it looks clickable */
  
  &:hover {
    color: var(--ion-color-secondary-shade);  /* Slightly change color on hover */
    text-decoration: none;                   /* Optionally remove underline on hover */
  }
  
  &:visited {
    color: var(--ion-color-secondary-tint);   /* Change the color for visited links */
  }

  &:active {
    color: var(--ion-color-secondary-contrast);  /* Change the color when the link is clicked */
  }
}

